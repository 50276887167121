<template>
  <ValidationObserver v-slot="{ handleSubmit }">
    <form id="send-comment-form" @submit.prevent="(e) => handleSubmit(() => submitComment(e, replyingTo))" class="mb-5">
      <div
          class="d-flex flex-xl-row flex-column flex-wrap justify-content-right fontsize13">
        <ValidationProvider name="name" mode="passive" class="mb-3 text-right" rules="required" v-slot="v">
          <input name="name" @change="v.validate" type="text" class="rtl-placeholder rtl bg-color-ea  py-1 px-2 border-radius10 ml-xl-2 mb-2"
                 placeholder="نام کامل">
          <span class="text-right text-danger mt-2 mx-2">{{ v.errors[0] }}</span>
        </ValidationProvider>
        <ValidationProvider name="email" mode="passive" class="mb-3 text-right" rules="required" v-slot="v">
          <input name="email" @change="v.validate" type="email" class="rtl bg-color-ea  border-radius10 py-1 px-2 ml-xl-2 mb-2"
                 placeholder=" آدرس ایمیل">
          <span class="text-right text-danger mt-2 mx-2">{{ v.errors[0] }}</span>
        </ValidationProvider>
      </div>
      <ValidationProvider name="text" mode="passive" class="mb-3 text-right" rules="required" v-slot="v">
          <textarea @change="v.validate" type="text" class="bg-color-ea border-radius10 py-1 px-2 w-100 mb-2 fontsize13" name="comment"
                    cols="30" rows="10" placeholder="دیدگاه شما ..."></textarea>
        <span class="text-right text-danger mt-2">{{ v.errors[0] }}</span>
      </ValidationProvider>
      <div class="text-left">
        <button :disabled="sendingRequest" type="submit"
                class="py-1 w-25 bg-color-themeBlue text-white fontsize13 font-weight-bold  border-radius10">ارسال پیام</button>
      </div>
    </form>
  </ValidationObserver>
</template>

<script>
import {ValidationObserver, ValidationProvider} from "vee-validate";

export default {
  name: "blogCommentForm",
  props: {
    submitComment: Function,
    sendingRequest: {
      default: false,
      type: Boolean
    },
    replyingTo: {
      default: null,
      type: Object,
    }
  },
  components: {ValidationProvider, ValidationObserver}
}
</script>

<style scoped>

</style>